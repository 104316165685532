import React from "react";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../../arrows";
import { Link } from "../../links";
import { TestimonialsContainer, TestimonialsHeading } from "./styles";
import { PpTestimonial, Testimonial } from "./Testimonial";

export type PpTestimonialsBlock = {
  testimonials: Array<{
    testimonial: PpTestimonial;
  }>;
};

export function TestimonialsBlock({
  testimonials,
}: PpTestimonialsBlock): React.ReactElement {
  const heading = "Real people. Real reviews";
  const settings = {
    arrows: true,
    autoplay: false,
    dots: true,
    infinite: true,
    nextArrow: <NextArrow />,
    pauseOnHover: false,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipe: true,
  };

  return (
    <TestimonialsContainer>
      <TestimonialsHeading>
        <Link withArrow to="/reviews" variant="h6">
          {heading}
        </Link>
      </TestimonialsHeading>
      <div>
        <Slider {...settings}>
          {testimonials.map(({ testimonial }) => (
            <Testimonial key={testimonial.name} {...testimonial} />
          ))}
        </Slider>
      </div>
    </TestimonialsContainer>
  );
}
