import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

export const TestimonialsContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    position: relative;
    max-width: 700px;
    margin: 0 auto;
    background-color: ${theme.palette.background.paper};
    border-radius: ${theme.shape.siteBorderRadius.lg};
    box-shadow: ${theme.shadows[1]};
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(4)};
    .slick-slide {
      padding: ${theme.spacing(0, 4)};
    }
    .slick-slider {
      position: static;
      ${theme.cssMixins.enableTextSelect};
    }
    .slick-prev {
      left: ${theme.spacing(-2.5)} !important;
      ${theme.breakpoints.up("md")} {
        left: ${theme.spacing(-3)} !important;
      }
    }
    .slick-next {
      right: ${theme.spacing(-2.5)} !important;
      ${theme.breakpoints.up("md")} {
        right: ${theme.spacing(-3)} !important;
      }
    }
    .slick-dots {
      bottom: ${theme.spacing(-5)};
      li {
        button::before {
          font-size: 12px;
          color: ${theme.palette.primary.main};
          opacity: 0.1;
          &:hover {
            color: ${theme.palette.primary.main};
            opacity: 0.1;
          }
        }
      }
      .slick-active {
        button::before {
          color: ${theme.palette.primary.light};
          opacity: 1;
        }
      }
    }
  `}
`;

export const TestimonialsHeading = styled("div")`
  ${({ theme }): SerializedStyles => css`
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};
    text-transform: uppercase;
    opacity: 0.2;
    color: ${theme.palette.primary.main};
    text-align: center;
    ${theme.breakpoints.up("md")} {
      .link-arrow {
        display: inline-block;
        transition: ${theme.transitions.create("transform", {
          duration: theme.transitions.duration.complex,
        })};
      }
      &:hover {
        .link-arrow {
          transform: translateX(${theme.spacing(1)});
        }
      }
    }
  `}
`;
