import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "../../../textFields";

export const TestimonialContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    text-align: center;
    height: 260px;
    width: 100%;
    ${theme.breakpoints.up("sm")} {
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    }
  `}
`;

export const UserInfo = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    width: 100%;
    margin-left: ${theme.spacing(2)};
  `}
`;

export const Name = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.lineClamp({ lines: 1 })};
  `}
`;

export const Message = styled(RichText)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.lineClamp({ lines: 6 })};
    ${theme.breakpoints.up("md")} {
      ${theme.cssMixins.lineClamp({ lines: 5 })};
    }
  `}
`;

export const Image = styled(GatsbyImage)`
  ${({ theme }): SerializedStyles => css`
    min-height: 45px;
    min-width: 45px;
    ${theme.breakpoints.up("sm")} {
      min-height: 60px;
      min-width: 60px;
    }
    img,
    > div {
      height: 45px;
      width: 45px;
      border-radius: ${theme.shape.siteBorderRadius.circle};
      ${theme.breakpoints.up("sm")} {
        height: 60px;
        width: 60px;
      }
    }
  `}
`;

export const Source = styled("div")`
  ${({ theme }): SerializedStyles => css`
    margin-left: ${theme.spacing(2)};
    ${theme.breakpoints.up("sm")} {
      margin-left: ${theme.spacing(3)};
    }
    > a {
      display: inline-block;
    }
  `}
`;

export const SourceLogo = styled("img")`
  max-height: 25px;
  max-width: 70px;
`;
