/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { StyledNextArrow } from "./styles";

type PpNextArrow = {
  isInverted?: boolean;
};

export function NextArrow({
  isInverted = false,
  ...rest
}: PpNextArrow): React.ReactElement {
  return (
    <StyledNextArrow $isInverted={isInverted} fontSize="large" {...rest} />
  );
}
