/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import { Stack, Typography } from "@mui/material";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { formatDate, formatFullName } from "../../../utils";
import {
  Image,
  Message,
  Name,
  Source,
  SourceLogo,
  TestimonialContainer,
  UserInfo,
} from "./styles";

export type PpTestimonial = {
  date: string;
  longMessage: string;
  name: string;
  photo: {
    alt: string;
    url: string;
    urlSharp: ImageDataLike;
  };
  sourceDetail: {
    logo: {
      title: string;
      url: string;
    };
    url: string;
  };
};

export function Testimonial({
  date,
  longMessage,
  name,
  photo,
  sourceDetail,
}: PpTestimonial): React.ReactElement {
  return (
    <TestimonialContainer>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={3}
        style={{ height: "100%" }}
      >
        <Message text={longMessage} variant="testimonial--sm" />
        <Stack alignItems="center" direction="row">
          <Image alt={photo.alt} image={getImage(photo.urlSharp)} />
          <UserInfo alignItems="center">
            <Name gutterBottom align="center" color="primary" variant="caption">
              {formatFullName(name)}
            </Name>
            {date && (
              <Typography
                align="center"
                color="primary.light"
                variant="caption"
              >
                {formatDate(date)}
              </Typography>
            )}
          </UserInfo>
          {sourceDetail && sourceDetail.logo && (
            <Source>
              <SourceLogo
                alt={sourceDetail.logo.title}
                src={sourceDetail.logo.url}
              />
            </Source>
          )}
        </Stack>
      </Stack>
    </TestimonialContainer>
  );
}
