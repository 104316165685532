/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { StyledPrevArrow } from "./styles";

type PpPrevArrow = {
  isInverted?: boolean;
};

export function PrevArrow({
  isInverted = false,
  ...rest
}: PpPrevArrow): React.ReactElement {
  return (
    <StyledPrevArrow $isInverted={isInverted} fontSize="large" {...rest} />
  );
}
