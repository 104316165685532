import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Theme } from "@mui/material";
import { shouldForwardProp } from "../utils";

type PpArrow = {
  $isInverted: boolean;
};

const baseArrowStyles = (
  theme: Theme,
  isInverted: boolean
): SerializedStyles => css`
  border-radius: 100%;
  filter: drop-shadow(${theme.shadows[1]});
  background-color: ${isInverted
    ? theme.palette.background.paper
    : theme.palette.primary.light} !important;
  color: ${isInverted
    ? theme.palette.primary.light
    : theme.palette.background.paper} !important;
  height: ${theme.spacing(5)} !important;
  width: ${theme.spacing(5)} !important;
  ${theme.breakpoints.up("md")} {
    height: ${theme.spacing(6)} !important;
    width: ${theme.spacing(6)} !important;
  }
  z-index: 1000;
  transition: ${theme.transitions.create("all", {
    duration: theme.transitions.duration.shorter,
  })};
  &:hover {
    background-color: ${isInverted
      ? theme.palette.background.paper
      : theme.palette.primary.light} !important;
    color: ${isInverted
      ? theme.palette.primary.light
      : theme.palette.background.paper} !important;
  }
  transform: translateY(-50%);
  &:focus,
  &:active {
    transform: translateY(-46%);
  }
`;

export const StyledNextArrow = styled(ChevronRightIcon, {
  shouldForwardProp,
})<PpArrow>`
  ${({ $isInverted, theme }): SerializedStyles => css`
    ${baseArrowStyles(theme, $isInverted)};
  `}
`;

export const StyledPrevArrow = styled(ChevronLeftIcon, {
  shouldForwardProp,
})<PpArrow>`
  ${({ $isInverted, theme }): SerializedStyles => css`
    ${baseArrowStyles(theme, $isInverted)};
  `}
`;
